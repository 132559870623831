var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-row',{staticClass:"justify-content-end"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1),_c('b-form-input',{attrs:{"placeholder":_vm.$t('Common.Search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[(_vm.$can('write', 'food'))?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"w-100",attrs:{"variant":"primary"},on:{"click":function () {
                  _vm.$router.push('/food/ingredient-cat-form/new');
                }}},[_vm._v(" "+_vm._s(_vm.$t("IngredientCategoryMaster.AddIngredientCategory"))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"box mt-2"},[_c('b-table',{staticClass:"position-relative",attrs:{"striped":"","hover":"","responsive":"","primary-key":"id","empty-text":"No matching records found","show-empty":"","per-page":_vm.pagination.perPage,"current-page":_vm.pagination.currentPage,"items":_vm.IngredientMainLists,"fields":_vm.fields},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [_vm._v(_vm._s(_vm.$t(data.label)))]}},{key:"cell(ingcategory_active)",fn:function(data){return [(_vm.$can('write', 'food'))?_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":"","checked":data.item.ingcategory_active == 1 ? true : false},on:{"change":function($event){return _vm.activateIngredientCat(data.item)}}}):_c('span',[_c('span',{class:data.item.ingcategory_active
                      ? 'badge text-capitalize badge-light-success badge-pill'
                      : 'badge text-capitalize badge-light-danger badge-pill'},[_vm._v(_vm._s(data.item.ingcategory_active ? "Active" : "In-Active"))])])]}},{key:"cell(edit)",fn:function(data){return [(_vm.$can('write', 'food'))?_c('feather-icon',{attrs:{"icon":"EditIcon"},on:{"click":function () {
                    _vm.$router.push(
                      ("/food/ingredient-cat-form/" + (data.item.ingcategory_id))
                    );
                  }}}):_c('feather-icon',{attrs:{"icon":"EyeIcon"},on:{"click":function () {
                    _vm.$router.push(
                      ("/food/ingredient-cat-form/" + (data.item.ingcategory_id))
                    );
                  }}})]}}])})],1),_c('b-card',{staticClass:"d-flex justify-content-between flex-wrap pt-0"},[_c('div',[_c('b-pagination',{staticClass:"mb-0",attrs:{"total-rows":_vm.allIngredientCatItemLists.length,"per-page":_vm.pagination.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }